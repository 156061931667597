<template>
  <div class="project-create">
    <Page title="Create a new project">
      <div class="flex justify-center">
        <form>
          <p>Create a new project by filling in the fields below and clicking <b>submit</b> when complete.</p>
          <div class="mt-8 max-w-md">
            <div class="grid grid-cols-1 gap-6">
            <Input
              field="title"
              inputclass="input--default"
              label="Title"
              required type="text"
              v-bind:errors="errors"
              v-model="project.title"/>
            <Input
              field="description"
              inputclass="input--default"
              label="Description"
              required type="text"
              v-bind:errors="errors"
              v-model="project.description"/>
            <div class="flex flex-row justify-between items-end">
              <Select
                v-if="!newClient"
                class="w-full"
                field="client"
                label="Client"
                required
                v-bind:errors="errors"
                v-bind:options="selectOptions($store.state.clients, 'name')"
                v-model="project.client"/>
              <Input
                v-else
                class="w-full"
                field="client"
                inputclass="input--default"
                label="Client"
                type="text"
                required
                v-bind:errors="errors"
                v-model="project.client_name"/>
              <Button
                @click="toggleNewClient()"
                class="
                  h-10 mb-0 w-full flex flex-row items-center mr-0
                  transition-colors text-primary border-primary
                  hover:text-primary-dark hover:border-primary-dark
                "
                buttontype="border"
                type="button">
                  <span class="block flex-auto mr-3">
                    {{ newClient ? 'Existing client' : 'Add new client' }}
                  </span>
                  <Icon
                    v-if="!newClient"
                    class="block flex-none h-6 w-6"
                    name="plus"/>
              </Button>
            </div>
            <Input
              field="point_of_contact"
              inputclass="input--default"
              label="Point of contact"
              required type="text"
              v-bind:errors="errors"
              v-model="project.point_of_contact"/>
            <Input
              field="client_budget_pence"
              inputclass="input--default"
              label="Client budget (£)"
              type="number"
              v-bind:errors="errors"
              v-model="project.client_budget_pence"/>
            <Input
              field="production_fee_pc"
              inputclass="input--default"
              label="Mark-up (%)"
              type="number"
              v-bind:errors="errors"
              v-model="project.production_fee_pc"/>
            </div>
          </div>
          <div class="mt-8 max-w-md">
          <Button
          class="max-w-xs mx-auto"
          @click="submitForm"
          buttontype="background"
          type="button">Submit</Button>
          </div>
        </form>
      </div>
    </Page>
  </div>
</template>

<script>
import Button from '@/components/button.vue'
import Input from '@/components/input.vue'
import Page from '@/components/page.vue'
import Select from '@/components/select.vue'
import Icon from '@/components/icon.vue'
import { selectOptions, postRequest, poundsToPence } from '@/components/js/utils.js'
import * as constants from '@/components/js/constants.js'

export default {
  name: 'ProjectCreate',
  created () {
    this.$store.dispatch('getClients')
  },
  components: {
    Button,
    Input,
    Page,
    Select,
    Icon
  },
  data () {
    return {
      // Container for any errors returned from the api
      errors: {},
      // Container for the data collected from the form
      project: {
        // TODO: `added_user` to be removed when merged with logon and authentication
        added_user: '',
        client: '',
        client_budget_pence: null,
        client_discount_pc: 0,
        description: '',
        // TODO: `modified_user` to be removed when merged with logon and authentication
        modified_user: '',
        point_of_contact: '',
        production_fee_pc: null,
        title: '',
        client_name: null
      },
      newClient: false
    }
  },
  methods: {
    selectOptions,
    submitForm: function (e) {
      // Clone post data and convert pounds value to pence if not null
      const postData = { ...this.$data.project }

      if (postData.client_budget_pence) {
        postData.client_budget_pence = poundsToPence(postData.client_budget_pence)
      }

      /**
       * call client API if user is adding a new client, else just call project API
       */
      if (this.$data.newClient) {
        postRequest('client', { name: this.$data.project.client_name }, (res) => {
          postData.client = res.data.id
          postRequest('project', postData, this.postSuccess, this.postError)
        }, this.postClientError)
      } else {
        postRequest('project', postData, this.postSuccess, this.postError)
      }
    },
    /**
     * Handles the error response from client API
     */
    postClientError: function (error) {
      if (error.response.data.name) {
        const errStr = error.response.data.name[0].charAt(0).toUpperCase() +
          error.response.data.name[0].slice(1)
        this.$store.commit('addAlert', {
          status: 'error',
          message: `Could not create Project. ${errStr}`,
          timer: 6000
        })
      }
      return error
    },
    /*
    Handles the error and saves any error strings returned to display to the user
    */
    postError: function (error) {
      if (error.response.status === constants.HTTP_400_BAD_REQUEST) {
        // Update errors with the returned data
        this.$data.errors = error.response.data

        if (error.response.data.non_field_errors) {
          if (error.response.data.non_field_errors[0] === 'max_projects exceeded') {
            this.$store.commit('addAlert', {
              status: 'error',
              timer: 8000,
              message: constants.maxProjectsMessage
            })
          }
        }

        // hack to show title unique validation in form
        if (
          this.$data.errors.non_field_errors &&
          this.$data.errors.non_field_errors.includes('The fields client, title must make a unique set.')
        ) {
          this.$data.errors.title = ['Title must be unique for each client']
        }
      } else {
        console.log(error)
      }
    },
    postRequest,
    poundsToPence,
    /*
    Outputs the success response to the user and redirects to the home view
    */
    postSuccess: function (response) {
      if (response.status === constants.HTTP_201_CREATED) {
        // TODO: Add a modal here to tell the user a new object was created?
      }
      this.$router.push('/')
    },
    toggleNewClient: function () {
      this.$data.newClient = !this.$data.newClient
    }
  }
}
</script>
